<template>
  <div id="forgot-password-wrapper" class="d-flex justify-center align-center">
    <v-card
      min-width="350px"
      max-width="400px"
      class="elevation-0"
      style="position: relative"
    >
      <v-avatar id="forgot-password-logo" size="90" class="elevation-1">
        <img :src="logo" alt="logo" style="width: 75px; height: 75px" />
      </v-avatar>
      <v-card-text class="text-center" style="padding: 50px 30px 20px 30px">
        <div class="font-weight-black mb-6" style="font-size: 20px">
          LUPA PASSWORD
        </div>
        <div class="body-2">
          Isikan Email anda, dan kami akan <br />
          mengirimkan atur ulang password.
        </div>

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :rules="[
                  (v) => !!v || 'Email harus diisi',
                  (v) => /.+@.+\..+/.test(v) || 'Format email salah',
                ]"
                name="login-email"
                label="Email"
                hide-details="auto"
                required
                outlined
                dense
              ></v-text-field>
              <v-btn
                color="primary"
                text
                :disabled="loading"
                class="px-8 mt-2 mr-2"
                @click="$router.push('/login')"
                >Batal</v-btn
              >
              <v-btn
                color="warning"
                depressed
                :loading="loading"
                class="px-8 mt-2"
                @click="forgotHandler"
                >Kirim</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="successSent" persistent width="300">
      <v-card>
        <v-card-text class="text-center pt-6">
          <img :src="logo" alt="logo" style="width: 72px; height: 72px" />
          <div class="font-weight-bold title">SELESAI</div>
          <div class="body-2">Harap mengecek email anda.</div>

          <v-btn
            color="warning"
            depressed
            class="px-6 mt-4"
            @click="$router.push('/login')"
            >Kembali</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { userForgotPassword } from "@/api/user";

import logo from "@/assets/logo.png";

export default {
  name: "Login",
  data() {
    return {
      valid: false,
      logo,
      showPassword: false,
      email: "",
      password: "",
      successSent: false,
      loading: false,
    };
  },
  methods: {
    forgotHandler() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        userForgotPassword({
          username: this.email,
        })
          .then(({ data }) => {
            if (data.code) this.successSent = true;
            else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: data.message,
                color: "error",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#forgot-password-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  #forgot-password-logo {
    background: white;
    position: absolute;
    left: 125px;
    top: -60px;
  }
}
</style>
